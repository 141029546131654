
import React, { useState, useEffect } from "react";
import { Page, Grid, Card, Alert, Form, Button } from "tabler-react";
import SiteWrapper from "../SiteWrapper";

import * as userService from "../store/services/userServices";



export default function MyAccount(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ loading, setLoading ] = useState(false);
    const [ id, setId ] = useState( user?.id );
    const [ password, setPassword ] = useState("password");
    const [ pwsConfirm, setPwsConfirm ] = useState("");
    const [ firstName, setFirstName ] = useState(user?.name || "");
    const [ lastName, setLastName ] = useState(user?.last_name || "");
    const [ email, setEmail ] = useState( user?.email || "" );
    const [ errors, setErrors ] = useState({});
    const [ saveSuccess, setSaveSuccess ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);

    const saveChanges = () => {
        setLoading(true);
        setErrors({});

        userService
            .updateAccount(id, { password: password, password_confirmation: pwsConfirm, firstName: firstName, lastName: lastName, email: email })
            .then((res) => {
                const data = JSON.parse(res.data);

                setFirstName(data.data.name || "");
                setLastName(data.data.last_name || "");
                setEmail(data.data.email || "");
                
                setSaveSuccess(true);

                setLoading(false);
            })
            .catch((err) => {
                setErrors(err?.response?.data?.errors || {});
                setLoading(false);
            });
    }

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="page-title">
                        My Account
                    </h1>

                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>
                                    Account Details - ID {String(id).padStart(4, '0')}
                                </Card.Title>

                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setPassword( isEditing ? "password": "" );
                                        setIsEditing( !isEditing );
                                    }}
                                >
                                    {
                                        isEditing ? 
                                        "Cancel": "Edit"
                                    }
                                </Button>
                            </Card.Header>

                            <Card.Body >

                                <Grid.Row className="mb-300 pb-5">
                                    <Grid.Col lg={2} width={ 12 }></Grid.Col>
                                    {
                                        !saveSuccess && 
                                        <Grid.Col lg={8} md={12} xs={12} sm={12} >
                                            <Grid.Row>
                                                <Grid.Col md={ 6 } width={ 12 } >
                                                    <Form.Group >
                                                        <Form.Input
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            value={firstName}
                                                            className="input-normal"
                                                            feedback={errors?.firstName && errors?.firstName[0]}
                                                            invalid={errors?.firstName}
                                                            readOnly={!isEditing}
                                                        />
                                                    </Form.Group>
                                                </Grid.Col>
                                                <Grid.Col md={ 6 } width={ 12 }>
                                                    <Form.Group >
                                                        <Form.Input
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            value={lastName}
                                                            className="input-normal"
                                                            feedback={errors?.lastName && errors?.lastName[0]}
                                                            invalid={errors?.lastName}
                                                            readOnly={!isEditing}
                                                        />
                                                    </Form.Group>
                                                </Grid.Col>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Col md={ 6 } width={ 12 }>
                                                    <Form.Group >
                                                        <Form.Input
                                                            name="email"
                                                            placeholder="Email Address"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            value={email}
                                                            className="input-normal"
                                                            feedback={ errors?.email && errors?.email[0] }
                                                            invalid={errors?.email}
                                                            readOnly={!isEditing}
                                                        />
                                                    </Form.Group>
                                                </Grid.Col>
                                                <Grid.Col md={ 6 } width={ 12 }>
                                                    <Form.Group >
                                                        <Form.Input
                                                            name="Password"
                                                            placeholder="Password"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            value={password}
                                                            className="input-normal"
                                                            feedback={ errors?.password && errors?.password[0] }
                                                            invalid={errors?.password}
                                                            type="password"
                                                            readOnly={!isEditing}
                                                        />
                                                    </Form.Group>
                                                </Grid.Col>
                                            </Grid.Row>
                                            {
                                                isEditing && 
                                                <Grid.Row>
                                                    <Grid.Col >
                                                        <Form.Group >
                                                            <Form.Input
                                                                name="pwsConfirm"
                                                                placeholder="Confirm new password"
                                                                onChange={(e) => setPwsConfirm(e.target.value)}
                                                                value={pwsConfirm}
                                                                className="input-normal"
                                                                feedback={ errors?.password_confirmation && errors?.password_confirmation[0] }
                                                                invalid={errors?.password_confirmation}
                                                                type="password"
                                                            />
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                            }
                                            {
                                                isEditing && 
                                                <Grid.Row>
                                                    <Button className="btn-barlows d-flex justify-content-center align-items-center ml-2" onClick={() => saveChanges()}>
                                                        Save Changes
                                                    </Button>
                                                </Grid.Row>
                                            }
                                        </Grid.Col>
                                    }

                                    {
                                        saveSuccess && 
                                        <Grid.Col lg={8} width={12} >
                                            <Grid.Row>
                                                <h1 className="page-header-title">Save Success</h1>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <label className="text-secondary">
                                                    {
                                                        id != 0 ?
                                                        "User successfully updated." : "New user successfully added."
                                                    }
                                                </label>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button
                                                    color=" btn-barlows d-flex justify-content-center align-items-center"
                                                    onClick={() => {
                                                        setPassword("");
                                                        setSaveSuccess(false);
                                                    }}
                                                >
                                                    Back To My Account
                                                </Button>
                                            </Grid.Row>
                                        </Grid.Col>
                                    }

                                    
                                </Grid.Row>

                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

import React, { useState, useEffect } from "react";
import { Page } from "tabler-react";
import SiteWrapper from "../SiteWrapper";



function EmptyPage(props) {

    console.log("empty page")
    const [ loading, setLoading ] = useState(false);
    

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
            </Page.Content>
        </SiteWrapper>
    )
}

export default EmptyPage;
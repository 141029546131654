
import React, { useState, useEffect, useRef } from "react";
import { Page, Grid, Card, Alert, Form, Button } from "tabler-react";
import SiteWrapper from "../../SiteWrapper";

import * as productServices from "../../store/services/productServices";
import { BACKENDURL } from "../../store/services/type";
import { ROLE_ADMIN } from "../../store/types";

import "../../index.css";


function ViewProduct(props) {

    const [ user, setUser ] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});
    const [ loading, setLoading ] = useState(false);
    const [ product, setProduct ] = useState(null);
    const [ measurements, setMeasurements ] = useState([]);

    useEffect(() => {
        setLoading(true);

        productServices
            .getProduct(props.match.params.id)
            .then(( res ) => {
                const data = JSON.parse(res.data);
                setProduct(data.data);
                setMeasurements(data?.data?.measurements || []);
                setLoading(false);
            })
            .catch(err => setLoading(false));
    }, [])

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="page-title">
                        Products
                    </h1>
                </div>

                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>
                                    {
                                        "Details"
                                    }
                                </Card.Title>

                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        props.history.push(`/products/${props.match.params.id}`);
                                    }}
                                >
                                    Edit
                                </Button>
                            </Card.Header>

                            <Card.Body >

                                <Grid.Row className="pb-5">
                                    <Grid.Col width={ 12 }>
                                        <Grid.Row>
                                            <Grid.Col xl={ 4 } md={ 6 } width={ 12 } >
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="PRODUCT" >
                                                            <Form.StaticText>
                                                                {
                                                                    product?.product
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="PRICE" >
                                                            <Form.StaticText>
                                                                {
                                                                    product?.price
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="ID" >
                                                            <Form.StaticText>
                                                                {
                                                                    product?.unique_id
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="CATEGORY" >
                                                            <Form.StaticText>
                                                                {
                                                                    product?.category?.category
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="URL" >
                                                            <Form.StaticText>
                                                                {
                                                                    product?.url
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                {
                                                    user?.type === ROLE_ADMIN && 
                                                    <Grid.Row>
                                                        <Grid.Col width={ 12 } >
                                                            <Form.Group label="DEALER" >
                                                                <Form.StaticText>
                                                                    {
                                                                        `${String(product?.user_id || "").padStart(4, '0')} - ${product?.dealer?.name || ""} ${product?.dealer?.last_name || ""}`
                                                                    }
                                                                </Form.StaticText>
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                }
                                            </Grid.Col>
                                            <Grid.Col xl={ 4 } md={ 6 } width={ 12 } >
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="DESCRIPTION" className="text-wrap" >
                                                            <Form.StaticText>
                                                                {
                                                                    product?.description
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="FEATURES" >
                                                            <Form.StaticText className="text-wrap">
                                                                {
                                                                    product?.features
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Col width={ 12 } >
                                                        <Form.Group label="DEMENTIONS" >
                                                            <Form.StaticText className="text-wrap">
                                                                {
                                                                    `Width: ${product?.width || ""}cm`
                                                                }
                                                                <br/>
                                                                {
                                                                    `Height: ${product?.height || ""}cm`
                                                                }
                                                                <br/>
                                                                {
                                                                    `Depth: ${product?.depth || ""}cm`
                                                                }
                                                                <br/>
                                                                {
                                                                    `Weight: ${product?.weight || ""}kg`
                                                                }
                                                            </Form.StaticText>
                                                        </Form.Group>
                                                    </Grid.Col>
                                                </Grid.Row>
                                            </Grid.Col>
                                            <Grid.Col xl={ 4 } md={ 6 } width={ 12 } >
                                                <Form.Group label="IMAGES" >
                                                    <div className="image-view-container">
                                                        {
                                                            product?.images?.map((data, index) => {
                                                                return (
                                                                    <div key={ index } className="image-tile">
                                                                        <img src={ `${BACKENDURL}storage/${data}` } alt="product" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Form.Group>
                                            </Grid.Col> 
                                        </Grid.Row>
                                        <Form.Group label="MEASUREMENTS" >
                                            <Grid.Row>
                                                {
                                                    measurements?.map((data, index) => {
                                                        return (
                                                            <Grid.Col md={ 4 } width={ 12 } key={ index } className="mt-13" >
                                                                <div className="measurement-tile-header">
                                                                    {
                                                                        data.measurement
                                                                    }
                                                                </div>
                                                                <div className="measurement-tile-body">
                                                                    {
                                                                        `Min ${data?.min} | Max ${data?.max}`
                                                                    }
                                                                </div>
                                                            </Grid.Col>
                                                        )
                                                    })
                                                }
                                            </Grid.Row>
                                        </Form.Group>

                                    </Grid.Col>
                                </Grid.Row>

                            </Card.Body>
                        
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default ViewProduct;
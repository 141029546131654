import { AUTH_LOGIN, AUTH_LOGOUT, USERS_ERRORS, SET_DETAILS_SUBMITTED } from "../types";

const initialState = {
  user: localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")),
  authToken: localStorage.getItem("authToken"),
  detailsSubmitted: localStorage.getItem("detailsSubmitted"),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        user: action.payload.user,
        authToken: action.payload.token,
      };
    case SET_DETAILS_SUBMITTED:
      return {
        ...state,
        detailsSubmitted: action.payload.detailsSubmitted,
      };
    case AUTH_LOGOUT:
      return {
        user: undefined,
        authToken: undefined,
      };
    default:
      return state;
  }
}

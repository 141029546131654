import { history } from "./history";
export default function setupAxios(axios, store) {
  // axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response && err.response.status === 401) {
        history.push("/login");
        localStorage.clear();
      } else {
        return Promise.reject(err);
      }
    }
  );
}

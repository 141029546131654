import axios from "axios";
import { BASE_URL } from "./type";

export function getMeasureDatas(page) {
    return axios.get(BASE_URL + `measure?page=${page}`);
}

export function getMeasure(id) {
    return axios.get(BASE_URL + `measure/${id}`);
}

export function getBase64Data(filePath) {
    return axios.get(`${BASE_URL}measure/getBase64Image?filePath=${filePath}`);
}

export function getBase64DataArray( id ) {
    return axios.get(`${BASE_URL}measure/getBase64DataArray?id=${ id }`);
}

export function updateMeasure(id, param) {
    return axios.put(`${BASE_URL}measure/${id}`, {...param});
}

export function deleteMeasure(id) {
    return axios.delete(`${BASE_URL}measure/${id}`);
}

export function saveTag(param) {
    return axios.post(`${BASE_URL}dealerTag`, { ...param });
}
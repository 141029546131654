import axios from "axios";
import { BASE_URL } from "./type";

export function getProducts(category, page, pagenation = 1) {
    if( category ) {
        return axios.get( `${BASE_URL}product?category=${category}&page=${page}${pagenation ? `&pagenation=1`: ''}`);
    } else {
        return axios.get( `${BASE_URL}product?page=${page}${pagenation ? `&pagenation=1`: ''}`);
    }
}

export function getProduct(id) {
    return axios.get(BASE_URL + "product/" + id);
}

export function saveProduct(formData) {
    return axios.post(BASE_URL + "product", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}

export function deleteProduct(id) {
    return axios.delete(BASE_URL + "product/" + id);
}

import React, { useState, useEffect, useRef } from "react";
import { Page, Grid, Card, Alert, Form, Button } from "tabler-react";
import { Modal } from "react-bootstrap";
import SiteWrapper from "../../SiteWrapper";

import * as productServices from "../../store/services/productServices";
import * as categoryServices from "../../store/services/categoryServices";
import { BACKENDURL } from "../../store/services/type";
import { ROLE_ADMIN, ROLE_DEALER, DEFAULT_PRODUCTS } from "../../store/types";
import { measuresConfig } from '../measure/viewMeasure';

const generateUniqueId = () => {
    return Math.floor(10000 + Math.random() * 90000).toString();
};

function EditProduct(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ loading, setLoading ] = useState(false);
    const [ id, setId ] = useState( Number( props.match.params.id ) );
    const [ errors, setErrors ] = useState({});
    const [ saveSuccess, setSaveSuccess ] = useState(false);
    const [ handleModalShow, setHandleModalShow] = useState(false);

    const [ selectedFiles, setSelectedFiles] = useState([]);
    const [ savedImages, setSavedImages] = useState([]);
    const [ deletedImages, setDeletedImages] = useState([]);
    const [ files, setFiles] = useState([]);
    const [ measurements, setMeasurements ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ dealerTag, setDealerTag ] = useState([]);
    const fileInputRef = useRef(null);


    const [ formElements, setFormElements ] = useState({
        unique_id: generateUniqueId(), 
        product: DEFAULT_PRODUCTS[0], 
        price: '', 
        dealer: '', 
        category: 0, 
        url: '', 
        width: '', 
        height: '', 
        depth: '', 
        weight: '', 
        description: '', 
        features: '', 
    });


    useEffect(() => {
        categoryServices
            .getCategories()
            .then(( res ) => {
                const data = JSON.parse(res.data);

                setCategories(data.data);
                setDealerTag(data?.dealerTag);

                setLoading(false);
            })
            .catch(( err ) => {
                setLoading(false);
            });

        if ( id > 0 ) {
            setLoading(true);
            productServices
                .getProduct(id)
                .then(( res ) => {
                    const data = JSON.parse(res.data);
                    
                    setFormElements({
                        unique_id: data?.data?.unique_id, 
                        product: data?.data?.product, 
                        price: data?.data?.price, 
                        dealer: data?.data?.user_id, 
                        category: data?.data?.category?.id, 
                        url: data?.data?.url, 
                        width: data?.data?.width, 
                        height: data?.data?.height, 
                        depth: data?.data?.depth, 
                        weight: data?.data?.weight, 
                        description: data?.data?.description, 
                        features: data?.data?.features, 
                    });

                    setMeasurements(data?.data?.measurements || []);
                    setSavedImages(data?.data?.images || []);

                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }, [  ])

    const saveProduct = () => {
        setLoading(true);
        setErrors({});

        var formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('images[]', files[i]);
        }

        if(deletedImages.length > 0) {
            for (let i = 0; i < deletedImages.length; i++) {
                formData.append('deletedImages[]', deletedImages[i]);
            }
        } else {
            formData.append('deletedImages', []);
        }

        for (let i = 0; i < savedImages.length; i++) {
            formData.append('savedImages[]', savedImages[i]);
        }

        formData.append("measurements", JSON.stringify(measurements));

        formData.append("id", id);

        for (const key in formElements) {
            formData.append(key, formElements[key]);
        }
        formData.append('user_id', user?.id);

        productServices
            .saveProduct( formData )
            .then((res) => {
                setSaveSuccess(true);

                setLoading(false);
            })
            .catch((err) => {
                setErrors(err?.response?.data?.errors || {});
                setLoading(false);
            });
    }

    const handleClose = () => {
        setHandleModalShow(false);
    }

    const deleteProduct = () => {
        productServices
            .deleteProduct(id)
            .then(res => {
                const data = JSON.parse(res.data);
                if(data?.message === "Success") {
                    props.history.push("/products");
                }
            })
            .catch(err => {

            })
    }







    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const InputChange = (e) => {
        if (e.target && e.target.files) {
            // --For Multiple File Input
            let images = [];
            for (let i = 0; i < e.target.files.length; i++) {
                images.push(e.target.files[i]);
                let reader = new FileReader();
                let file = e.target.files[i];
                setFiles((prev) => [...prev, file]);
                reader.onloadend = () => {
                    setSelectedFiles((preValue) => [
                        ...preValue,
                        {
                            // id: shortid.generate(),
                            filename: file.name,
                            filetype: file.type,
                            fileimage: reader.result,
                            // datetime: file.lastModifiedDate.toLocaleString('en-IN'),
                            filesize: filesizes(file.size)
                        }
                    ]);
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
            }
        } else {
            console.error("File input target is null or undefined.");
        }
    };


    const DeleteSelectFile = (index) => {
        setSelectedFiles((prevFiles) => [
            ...prevFiles.slice(0, index),
            ...prevFiles.slice(index + 1)
        ]);
    }

    const DeleteSavedFile = (index) => {
        const newImages = savedImages.filter((image, i) => i === index);
        setDeletedImages((prev) => [...prev, ...newImages]);
        setSavedImages((prevFiles) => [
            ...prevFiles.slice(0, index),
            ...prevFiles.slice(index + 1)
        ]);
    }

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="page-title">
                        Products
                    </h1>
                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>
                                    {
                                        id === 0 ? 
                                        "Details" : `Edit Product - ID ${String(id).padStart(4, '0')}`
                                    }
                                </Card.Title>

                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        props.history.push("/products");
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Card.Header>

                            <Card.Body >

                                <Grid.Row className="pb-5">
                                    {
                                        !saveSuccess && 
                                        <Grid.Col width={ 12 }>
                                            <Grid.Row>
                                                <Grid.Col xl={ 6 } width={ 12 } >
                                                    <Grid.Row>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="PRODUCT" >
                                                                <Form.Select
                                                                    placeholder="Product Name"
                                                                    onChange={(e) => {
                                                                        console.log(e.target.value)
                                                                        setFormElements({...formElements, product: e.target.value})
                                                                    }}
                                                                    value={formElements.product}
                                                                    className="input-normal"
                                                                    feedback={errors?.product && errors?.product[0]}
                                                                    invalid={errors?.product}
                                                                >
                                                                    {
                                                                        DEFAULT_PRODUCTS.map((product, index) => {
                                                                            return (
                                                                                <option key={ index }>{ product }</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Grid.Col>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="PRICE" >
                                                                <Form.Input
                                                                    placeholder="Price"
                                                                    onChange={(e) => setFormElements({...formElements, price: e.target.value})}
                                                                    value={formElements.price}
                                                                    className="input-normal"
                                                                    feedback={errors?.price && errors?.price[0]}
                                                                    invalid={errors?.price}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                    {
                                                        user?.type === ROLE_ADMIN && 
                                                        <Grid.Row>
                                                            <Grid.Col md={ 6 } width={ 12 } >
                                                                <Form.Group label="ID" >
                                                                    <Form.Input
                                                                        placeholder="00000"
                                                                        onChange={(e) => setFormElements({...formElements, unique_id: e.target.value})}
                                                                        value={formElements.unique_id}
                                                                        className="input-normal"
                                                                        feedback={errors?.unique_id && errors?.unique_id[0]}
                                                                        invalid={errors?.unique_id}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Grid.Col>
                                                            <Grid.Col md={ 6 } width={ 12 } >
                                                                <Form.Group label="DEALER" >
                                                                    <Form.Input
                                                                        placeholder="dealer"
                                                                        onChange={(e) => setFormElements({...formElements, dealer: e.target.value})}
                                                                        value={formElements.dealer}
                                                                        className="input-normal"
                                                                        feedback={errors?.dealer && errors?.dealer[0]}
                                                                        invalid={errors?.dealer}
                                                                    />
                                                                </Form.Group>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }

                                                    {
                                                        user?.type === ROLE_DEALER && 
                                                        <Grid.Row>
                                                            <Grid.Col width={ 12 } >
                                                                <Form.Group label="ID" >
                                                                    <Form.Input
                                                                        placeholder="00000"
                                                                        onChange={(e) => setFormElements({...formElements, unique_id: e.target.value})}
                                                                        value={formElements.unique_id}
                                                                        className="input-normal"
                                                                        feedback={errors?.unique_id && errors?.unique_id[0]}
                                                                        invalid={errors?.unique_id}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Grid.Col>
                                                        </Grid.Row>
                                                    }

                                                    <Grid.Row>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="CATEGORY" >
                                                                {/* <Form.Input
                                                                    placeholder="Category"
                                                                    onChange={(e) => setFormElements({...formElements, category: e.target.value})}
                                                                    value={formElements.category}
                                                                    className="input-normal"
                                                                    feedback={errors?.category && errors?.category[0]}
                                                                    invalid={errors?.category}
                                                                /> */}

                                                                <Form.Select
                                                                    placeholder="Category"
                                                                    onChange={(e) => setFormElements({...formElements, category: e.target.value})}
                                                                    value={formElements.category}
                                                                    className="input-normal"
                                                                    feedback={errors?.category && errors?.category[0]}
                                                                    invalid={errors?.category}
                                                                >
                                                                    <option></option>
                                                                    {
                                                                        categories.map((category, index) => {
                                                                            return (
                                                                                <option value={ category?.id } key={ index }>{category?.category}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Grid.Col>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="URL" >
                                                                <Form.Input
                                                                    placeholder="https://domain.com"
                                                                    onChange={(e) => setFormElements({...formElements, url: e.target.value})}
                                                                    value={formElements.url}
                                                                    className="input-normal"
                                                                    feedback={errors?.url && errors?.url[0]}
                                                                    invalid={errors?.url}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="WIDTH" >
                                                                <Form.Input
                                                                    placeholder="cm"
                                                                    onChange={(e) => setFormElements({...formElements, width: e.target.value})}
                                                                    value={formElements.width}
                                                                    className="input-normal"
                                                                    feedback={errors?.width && errors?.width[0]}
                                                                    invalid={errors?.width}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="HEIGHT" >
                                                                <Form.Input
                                                                    placeholder="cm"
                                                                    onChange={(e) => setFormElements({...formElements, height: e.target.value})}
                                                                    value={formElements.height}
                                                                    className="input-normal"
                                                                    feedback={errors?.height && errors?.height[0]}
                                                                    invalid={errors?.height}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="DEPTH" >
                                                                <Form.Input
                                                                    placeholder="cm"
                                                                    onChange={(e) => setFormElements({...formElements, depth: e.target.value})}
                                                                    value={formElements.depth}
                                                                    className="input-normal"
                                                                    feedback={errors?.depth && errors?.depth[0]}
                                                                    invalid={errors?.depth}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                        <Grid.Col md={ 6 } width={ 12 } >
                                                            <Form.Group label="WEIGHT" >
                                                                <Form.Input
                                                                    placeholder="kg"
                                                                    onChange={(e) => setFormElements({...formElements, weight: e.target.value})}
                                                                    value={formElements.weight}
                                                                    className="input-normal"
                                                                    feedback={errors?.weight && errors?.weight[0]}
                                                                    invalid={errors?.weight}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Col width={ 12 } >
                                                            <Form.Group label="DESCRIPTION" >
                                                                <Form.Textarea
                                                                    placeholder="Description"
                                                                    onChange={(e) => setFormElements({...formElements, description: e.target.value})}
                                                                    value={formElements.description}
                                                                    className="input-normal"
                                                                    feedback={errors?.description && errors?.description[0]}
                                                                    invalid={errors?.description}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>

                                                    <Grid.Row>
                                                        <Grid.Col width={ 12 } >
                                                            <Form.Group label="FEATURES" >
                                                                <Form.Textarea
                                                                    placeholder="• Features"
                                                                    onChange={(e) => setFormElements({...formElements, features: e.target.value})}
                                                                    value={formElements.features}
                                                                    className="input-normal"
                                                                    feedback={errors?.features && errors?.features[0]}
                                                                    invalid={errors?.features}
                                                                />
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                </Grid.Col>
                                                <Grid.Col xl={ 6 } width={ 12 } >
                                                    <Form.Group label="IMAGES" >

                                                        <div className="imag-tile-container">

                                                            {
                                                                savedImages.map((url, index) => {
                                                                    return (
                                                                        <div className="wrapper" key={index}>
                                                                            <div className="image-tile"> <img src={ `${BACKENDURL}storage/${url}` } alt="" /></div>
                                                                            <Button type="button" color="secondary" onClick={() => DeleteSavedFile(index)}>Delete</Button>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            
                                                            {
                                                                selectedFiles.map((data, index) => {
                                                                    const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                                    return (
                                                                        <div className="wrapper" key={index}>
                                                                            {
                                                                                filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                                    <div className="image-tile"> <img src={fileimage} alt="" /></div> :
                                                                                    <div className="image-tile"><i className="far fa-file-alt"></i></div>
                                                                            }
                                                                            <Button type="button" color="secondary" onClick={() => DeleteSelectFile(index)}>Delete</Button>
                                                                        </div>
                                                                    )
                                                                })
                                                            }


                                                            <div className="wrapper">
                                                                <input type="file" ref={fileInputRef} className="hidden" onChange={InputChange} multiple accept=".png, .jpg, .jpeg" />
                                                                <div className="image-tile add"  onClick={() => {
                                                                    if(fileInputRef?.current) fileInputRef.current.click();
                                                                }}>
                                                                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M39.7511 2.5V77" stroke="white" strokeWidth="5" strokeLinecap="square"/>
                                                                        <path d="M77 39.7501H2.5" stroke="white" strokeWidth="5" strokeLinecap="square"/>
                                                                    </svg>
                                                                </div>

                                                                <Button type="button" color="secondary" onClick={() => {
                                                                    if(fileInputRef?.current) fileInputRef.current.click();
                                                                }}>Add</Button>
                                                            </div>
                                                        </div>


                                                    </Form.Group>
                                                </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Col width={ 12 }>
                                                    {
                                                        [...measurements]?.map((measurement, index) => (
                                                            <Grid.Row key={index}>
                                                                <Grid.Col md={ 5 } width={ 12 } >
                                                                    <Form.Group label="MEASUREMENTS" >
                                                                        <Form.Select
                                                                            placeholder="Measurement"
                                                                            onChange={(e) => {
                                                                                const { value } = e.target;
                                                                                setMeasurements((prevMeasure) => {
                                                                                    const newMeasurements = [...prevMeasure];
                                                                                    newMeasurements[index] = {
                                                                                        ...newMeasurements[index],
                                                                                        measurement: value
                                                                                    };
                                                                                    return newMeasurements;
                                                                                });
                                                                            }}
                                                                            value={measurement.measurement}
                                                                            className="input-normal"
                                                                        >
                                                                            {
                                                                                measuresConfig?.map((measure, index) => (
                                                                                    <option key={index} value={measure.name}>{measure.label}</option>
                                                                                ))
                                                                            }
                                                                            <option value={'Height'}>Height</option>
                                                                            <option value={'Weight'}>Weight</option>
                                                                            {
                                                                                dealerTag?.tags?.map((tag, index) => (
                                                                                    <option key={index} value={tag}>{tag}</option>
                                                                                ))
                                                                            }
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Grid.Col>
                                                                <Grid.Col md={ 3 } width={ 12 } >
                                                                    <Form.Group label="MIN" >
                                                                        <Form.Input
                                                                            placeholder="cm"
                                                                            onChange={(e) => {
                                                                                const { value } = e.target;
                                                                                setMeasurements((prevMeasure) => {
                                                                                    const newMeasurements = [...prevMeasure];
                                                                                    newMeasurements[index] = {
                                                                                        ...newMeasurements[index],
                                                                                        min: value
                                                                                    };
                                                                                    return newMeasurements;
                                                                                });
                                                                            }}
                                                                            value={measurement.min}
                                                                            className="input-normal"
                                                                        />
                                                                    </Form.Group>
                                                                </Grid.Col>
                                                                <Grid.Col md={ 3 } width={ 12 } >
                                                                    <Form.Group label="MAX" >
                                                                        <Form.Input
                                                                            placeholder="cm"
                                                                            onChange={(e) => {
                                                                                const { value } = e.target;
                                                                                setMeasurements((prevMeasure) => {
                                                                                    const newMeasurements = [...prevMeasure];
                                                                                    newMeasurements[index] = {
                                                                                        ...newMeasurements[index],
                                                                                        max: value
                                                                                    };
                                                                                    return newMeasurements;
                                                                                });
                                                                            }}
                                                                            value={measurement.max}
                                                                            className="input-normal"
                                                                        />
                                                                    </Form.Group>
                                                                </Grid.Col>
                                                                <Grid.Col md={ 1 } width={ 12 } className="v-align" >
                                                                    <Button
                                                                        color="secondary"
                                                                        onClick={() => {
                                                                            setMeasurements((prev) => [
                                                                                ...prev.slice(0, index),
                                                                                ...prev.slice(index + 1)
                                                                            ]);
                                                                        }}
                                                                        className="btn-remove"
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </Grid.Col>
                                                            </Grid.Row>
                                                        ))
                                                    }
                                                    <div className="line-button">
                                                        <Button
                                                            color="secondary"
                                                            onClick={() => {
                                                                setMeasurements((prev) => [...prev, {
                                                                    measurement: measuresConfig[0].name, 
                                                                    min: '', 
                                                                    max: ''
                                                                }])
                                                            }}
                                                        >
                                                            Add Measurement &nbsp;
                                                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.5 1V8" stroke="black" strokeWidth="2" strokeLinecap="square"/>
                                                                <path d="M8 4.5H1" stroke="black" strokeWidth="2" strokeLinecap="square"/>
                                                            </svg>

                                                        </Button>
                                                    </div>
                                                </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button className="btn-barlows d-flex justify-content-center align-items-center ml-2" onClick={() => saveProduct()}>
                                                    {
                                                        id == 0 ?
                                                        "Add Product" : "Save Changes"
                                                    }
                                                    {
                                                        id == 0 && 
                                                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.5 1.5V8.5" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                                            <path d="M8 5H1" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                                        </svg>
                                                    }

                                                </Button>
                                            </Grid.Row>
                                        </Grid.Col>
                                    }
                                        <Grid.Col lg={2} width={12} ></Grid.Col>
                                    {
                                        saveSuccess && 
                                        <Grid.Col lg={8} width={12} >
                                            <Grid.Row>
                                                <h1 className="page-header-title">Save Success</h1>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <label className="text-secondary">
                                                    {
                                                        id != 0 ?
                                                        "Product successfully updated." : "New product successfully added."
                                                    }
                                                </label>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button
                                                    color=" btn-barlows d-flex justify-content-center align-items-center"
                                                    onClick={() => {
                                                        props.history.push("/products");
                                                    }}
                                                >
                                                    Back To Products
                                                </Button>
                                            </Grid.Row>
                                        </Grid.Col>
                                    }

                                    
                                </Grid.Row>

                            </Card.Body>
                            
                            {
                                id > 0 && 
                                <Card.Header>
                                    <div className='d-flex align-items-center'>
                                        <a href="#" className="btn btn-outline-danger d-flex align-items-center py-2" onClick={() => setHandleModalShow(true)}>Delete Product</a>
                                        <label className=' text-danger mb-0 ml-5'>Note: The product will no longer be able to see.</label>
                                    </div>
                                </Card.Header>
                            }
                        
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>


            <Modal
                show={handleModalShow}
                centered={true}
                onHide={handleClose}
                dialogClassName="delete-user cancel-subscription"
            >
                <Modal.Body>
                    <div
                        style={{ textAlign: "right", cursor: "pointer" }}
                        className='close-icon'
                    >
                        <svg
                        width="21px"
                        height="20px"
                        viewBox="0 0 32 32"
                        onClick={handleClose}
                        >
                        <g
                            stroke="#E5E9EF"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                            transform="translate(-419.000000, -240.000000)"
                            fill="#E5E9EF"
                            >
                            <g
                                id="icons"
                                transform="translate(56.000000, 160.000000)"
                            >
                                <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                            </g>
                            </g>
                        </g>
                        </svg>
                    </div>
                    <div className="d-flex justify-content-center align-items-center notification-content">
                        <svg width="50px" height="50px" viewBox="0 0 50 50">
                        <g
                            id="Desktop-App---BRANDED-V2"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                            id="Settings---Delete-User-Modal"
                            transform="translate(-695.000000, -376.000000)"
                            fill="#FF4747"
                            >
                            <g
                                id="Group-8"
                                transform="translate(530.000000, 336.000000)"
                            >
                                <g
                                id="Group-6"
                                transform="translate(165.000000, 40.000000)"
                                >
                                <rect
                                    id="Rectangle"
                                    fillOpacity="0.1"
                                    x="0"
                                    y="0"
                                    width="50"
                                    height="50"
                                    rx="5"
                                ></rect>
                                <g
                                    id="delete"
                                    transform="translate(15.000000, 15.000000)"
                                >
                                    <path
                                    d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z"
                                    id="Shape"
                                    ></path>
                                </g>
                                </g>
                            </g>
                            </g>
                        </g>
                        </svg>
                        <div className="content-title">Delete Product?</div>
                        <div className="content-note">
                            Are you sure? This cannot be undone.
                        </div>
                        <div
                        className="d-flex user-page"
                        style={{ marginTop: 20 }}
                        >
                        <Button
                            outline
                            color="danger"
                            onClick={ deleteProduct }
                        >
                            Yes, Delete Product
                        </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </SiteWrapper>
    )
}

export default EditProduct;
import React, { useState, useEffect } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";


import SiteWrapper from "../../SiteWrapper";
import * as categoryServices from '../../store/services/categoryServices';
import { ROLE_ADMIN, ROLE_DEALER, ROLE_CUSTOMER } from "../../store/types";



function Categories(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ loading, setLoading ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ filter, setFilter ] = useState(user.type === ROLE_DEALER ? ROLE_CUSTOMER: "");

    useEffect(() => {
        setLoading(true);

        categoryServices
            .getCategories()
            .then(res => {
                const data = JSON.parse(res.data);
                
                setCategories(data.data);
                setLoading(false);
            })
            .catch(err => setLoading(false));
    }, []);

    const handleFilter = () => {

    }

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="page-title">Categories</h1>

                    <Button 
                        className="btn-barlows d-flex justify-content-center align-items-center"
                        onClick={() => {
                            props.history.push("/categories/0");
                        }}
                    >
                        Add New
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 1.5V8.5" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                            <path d="M8 5H1" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                        </svg>
                    </Button>
                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>Categories</Card.Title>
                            </Card.Header>
                        {categories.length > 0 ?
                            <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table card-table table-vcenter datatable"
                            >
                            <Table.Header>
                                <Table.Row>
                                <Table.ColHeader>Category Name</Table.ColHeader>
                                <Table.ColHeader />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {categories.map((category, key) => (
                                <Table.Row key={key}>
                                    <Table.Col>{ category?.category }</Table.Col>
                                    <Table.Col>
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                                props.history.push("/categories/" + category.id);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </Table.Col>
                                </Table.Row>
                                ))}
                            </Table.Body>
                            </Table> :
                            <Card.Body className='align-items-center justify-content-center'>
                            <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                                No Category
                            </h4>
                            <div className="detail mb-5">You haven’t made any category yet.</div>
                            </Card.Body>
                        }
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default Categories;
import axios from "axios";
import { BASE_URL } from "./type";

export function getUsers(query, dealer = 0, page) {
    return axios.get(BASE_URL + `user?role=${query}&dealer=${dealer}&page=${page}`);
}

export function getUser(id) {
    return axios.get(BASE_URL + `user/one?id=${id}`);
}

export function saveUser(data) {
    return axios.post(BASE_URL + `user`, { ...data });
}

export function deleteUser(id) {
    return axios.delete( BASE_URL + `user/${id}` );
}


export function updateAccount(id, data) {
    return axios.patch( BASE_URL + `user/${id}`, { ...data } );
}
import React, { useState, useEffect } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";


import SiteWrapper from "../../SiteWrapper";
import * as productServices from '../../store/services/productServices';
import * as categoryServices from '../../store/services/categoryServices';
import { ROLE_ADMIN, ROLE_DEALER, ROLE_CUSTOMER } from "../../store/types";



function Products(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ loading, setLoading ] = useState(false);
    const [ products, setProducts ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ selectedCategory, setSelectedCategory ] = useState(0);

    const [ totalPage, setTotalPage ] = useState(1);
    const [ currentPage, setCurrentPage ] = useState(1);

    useEffect(() => {
        setLoading(true);

        categoryServices
            .getCategories()
            .then(( res ) => {
                const data = JSON.parse(res.data);

                setCategories(data?.data);
                setLoading(false);
            })
            .catch(( err ) => {
                setLoading(false);
            });

        
    }, []);

    useEffect(() => {
        setLoading(true);
        productServices
            .getProducts(selectedCategory, currentPage)
            .then(res => {
                const data = JSON.parse(res.data);

                setTotalPage(data?.data?.last_page);
                setCurrentPage(data?.data?.current_page);
                setProducts(data?.data?.data);
                setLoading(false);
            })
            .catch(err => setLoading(false));
    }, [ selectedCategory, currentPage ]);

    

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="page-title">Products</h1>

                    <Button 
                        className="btn-barlows d-flex justify-content-center align-items-center"
                        onClick={() => {
                            props.history.push("/products/0");
                        }}
                    >
                        Add New
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 1.5V8.5" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                            <path d="M8 5H1" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                        </svg>
                    </Button>
                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>Products</Card.Title>

                                {
                                    user.type !== "Dealer" && 
                                    <Form.Group >
                                        <Form.Select 
                                            onChange={(e) => setSelectedCategory(e.target.value)} 
                                            value={selectedCategory}
                                        >
                                            <option value="">All Categories</option>
                                            {
                                                categories?.map((category, index) => 
                                                    <option value={category.id} key={ index }>{category.category}</option>
                                                )
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                }
                            </Card.Header>
                        {products.length > 0 ?
                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                className="table card-table table-vcenter datatable"
                            >
                                <Table.Header>
                                    <Table.Row>
                                    <Table.ColHeader>ID</Table.ColHeader>
                                    <Table.ColHeader>Price</Table.ColHeader>
                                    <Table.ColHeader>Product</Table.ColHeader>
                                    <Table.ColHeader>Category</Table.ColHeader>
                                    {
                                        user?.type === ROLE_ADMIN && 
                                        <Table.ColHeader>Dealer</Table.ColHeader>
                                    }
                                    <Table.ColHeader />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {products.map((product, key) => (
                                    <Table.Row key={key}>
                                        <Table.Col>{ product.id }</Table.Col>
                                        <Table.Col>{ product?.product }</Table.Col>
                                        <Table.Col>{ product?.price }</Table.Col>
                                        <Table.Col>{ product?.category?.category}</Table.Col>
                                        {
                                            user?.type === ROLE_ADMIN && 
                                            <Table.Col>
                                                { 
                                                    product?.dealer?.type === ROLE_DEALER && 
                                                    product?.dealer?.id && 
                                                    `${String(product?.user_id || "").padStart(4, '0')} - ${product?.dealer?.name || ""} ${product?.dealer?.last_name || ""}` 
                                                }
                                            </Table.Col>
                                        }
                                        <Table.Col>
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                            props.history.push("/products/view/" + product.id);
                                            }}
                                        >
                                            View
                                        </Button>
                                        </Table.Col>
                                    </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table> :
                            <Card.Body className='align-items-center justify-content-center'>
                            <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                                No Products
                            </h4>
                            <div className="detail mb-5">You haven’t made any product yet.</div>
                            </Card.Body>
                            }
                            <Card.Footer className="justify-between">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCurrentPage((prev) => {
                                            if(prev == 1) {
                                                return prev;
                                            } else {
                                                return prev - 1;
                                            }
                                        });
                                    }}
                                >
                                    Prev
                                </Button>
                                <div>
                                    {
                                        `${currentPage} of ${totalPage}`
                                    }
                                </div>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCurrentPage((prev) => {
                                            if(prev + 1 > totalPage) {
                                                return prev;
                                            } else {
                                                return prev + 1;
                                            }
                                        });
                                    }}
                                >
                                    Next
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default Products;
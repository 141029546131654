import React from "react";
import { Route, Redirect } from "react-router-dom";
const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  if (!token) return false;
  return true;
};

const checkRole = (roles) => {
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  return roles && roles.indexOf(user?.type) > -1;
};
export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        checkRole(roles) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

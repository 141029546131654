import axios from "axios";
import { BASE_URL } from "./type";

export function getTags() {
    return axios.get(BASE_URL + `tag`);
}

export function getTag(id) {
    return axios.get(BASE_URL + "tag/" + id);
}

export function saveTag(formData) {
    return axios.post(BASE_URL + "tag", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}

export function deleteTag(id) {
    return axios.delete(BASE_URL + "tag/" + id);
}

import React, { useState, useEffect } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import SiteWrapper from "../../SiteWrapper";

import * as measureService from "../../store/services/measureServices";
import { ROLE_ADMIN } from "../../store/types";


function Measure(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ loading, setLoading ] = useState(false);
    const [ measures, setMeasures ] = useState([]);

    const [ totalPage, setTotalPage ] = useState(1);
    const [ currentPage, setCurrentPage ] = useState(1);


    useEffect(() => {
        setLoading(true);

        measureService
            .getMeasureDatas(currentPage)
            .then(res => {
                const data = JSON.parse(res.data);

                setMeasures(data?.data?.data);
                setTotalPage(data?.data?.last_page);
                setCurrentPage(data?.data?.current_page);
                
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })

    }, [ currentPage ])

    const convertDateFormat = (timestamp) => {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1
        const year = date.getFullYear() % 100; // Get the last two digits of the year
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }

    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header">
                    <h1 className="page-title">Measure</h1>
                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Latest Measures</Card.Title>
                            </Card.Header>
                        {measures.length > 0 ?
                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                className="table card-table table-vcenter datatable"
                            >
                                <Table.Header>
                                    <Table.Row>
                                    <Table.ColHeader>DATE</Table.ColHeader>
                                    <Table.ColHeader>ID</Table.ColHeader>
                                    <Table.ColHeader>Product</Table.ColHeader>
                                    <Table.ColHeader>Side Active</Table.ColHeader>
                                    {
                                        user?.type === ROLE_ADMIN && 
                                        <Table.ColHeader>Dealer</Table.ColHeader>
                                    }
                                    <Table.ColHeader />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {measures.map((measure, key) => (
                                    <Table.Row key={key}>
                                        <Table.Col className="fit-content">
                                        { convertDateFormat(measure.date)}
                                        </Table.Col>
                                        <Table.Col>{ measure.id }</Table.Col>
                                        <Table.Col>{ measure?.product }</Table.Col>
                                        <Table.Col>{ measure.sideBody === 1 ? "Yes": "No" }</Table.Col>
                                        {
                                            user?.type === ROLE_ADMIN && 
                                            <Table.Col>
                                                { 
                                                    measure?.user?.dealer?.id &&
                                                    `${String(measure?.user?.dealer?.id || "").padStart(4, '0')} - ${measure?.user?.dealer?.name || ""} ${measure?.user?.dealer?.last_name || ""}` 
                                                }
                                            </Table.Col>

                                        }
                                        <Table.Col>
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                            props.history.push( `/measure/${measure.id}?measure=&count=&metric=`);
                                            }}
                                        >
                                            View
                                        </Button>
                                        </Table.Col>
                                    </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table> :
                            <Card.Body className='align-items-center justify-content-center'>
                                <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                                    No Measures
                                </h4>
                                <div className="detail mb-5">You haven’t made any measure value yet.</div>
                            </Card.Body>
                            }
                            <Card.Footer className="justify-between">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCurrentPage((prev) => {
                                            if(prev == 1) {
                                                return prev;
                                            } else {
                                                return prev - 1;
                                            }
                                        });
                                    }}
                                >
                                    Prev
                                </Button>
                                <div>
                                    {
                                        `${currentPage} of ${totalPage}`
                                    }
                                </div>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setCurrentPage((prev) => {
                                            if(prev + 1 > totalPage) {
                                                return prev;
                                            } else {
                                                return prev + 1;
                                            }
                                        });
                                    }}
                                >
                                    Next
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default Measure;
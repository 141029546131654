import axios from "axios";
import { BASE_URL } from "./type";

export function getCategories() {
    return axios.get(BASE_URL + `category`);
}

export function getCategory(id) {
    return axios.get(BASE_URL + "category/" + id);
}

export function saveCategory(formData) {
    return axios.post(BASE_URL + "category", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}

export function deleteCategory(id) {
    return axios.delete(BASE_URL + "category/" + id);
}
import React, { useEffect, useState } from "react";
import { Page, Grid, Form, Alert, Error400Page } from "tabler-react";
import { NavLink, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as authService from "../store/services/authServices";
import PageFooter from "./PageFooter";

const SuccessPage = () => {
  const history = useHistory();
  const goToLogin = () => {
    history.push("/login");
  };
  return (
    <Page>
      <div className="login-page">
        <Grid.Row>
          <div className="col-lg-6">
            <Grid.Row>
              <div
                className="col-md-6 col-lg-8 form-body"
                style={{ marginTop: "auto" }}
              >
                <label className="welcome-title">Email Verified!</label>
                <label className="label-title">
                  {/* Your email has been successfully verified. You can now Log In. */}
                  Please go back to the native app to login.
                </label>
                {/* <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                  autoComplete="off"
                >
                  <button type="submit" className="btn btn-block btn-barlows">
                    Back to Log In
                  </button>
                </form> */}
              </div>
            </Grid.Row>
          </div>
          <div className="col-lg-6 ">
            <div className="ev-logo verified-logo"></div>
          </div>
        </Grid.Row>
        <PageFooter />
      </div>
    </Page>
  );
};
function VerifyEmailPage(props) {
  const params = new URLSearchParams(props.location.search);
  const path = params.get("queryURL");
  const [isLoading, setSubmit] = useState(false);
  const [isVerified, setRequested] = useState(false);
  const [alert, setAlert] = useState({});

  useEffect(() => {
    if (path) {
      setSubmit(true);
      authService
        .verifyEmail(path)
        .then((res) => {
          setSubmit(false);
          setRequested(true);
        })
        .catch((error) => {
          setSubmit(false);
        });
    } else {
    }
  }, []);
  return !isLoading && (isVerified ? <SuccessPage /> : <Error400Page />);
}

export default withRouter(VerifyEmailPage);

import React, { useState } from "react";
import { Formik, Field } from "formik";
import { Page, Grid, Form, Alert } from "tabler-react";
import { NavLink, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as authService from "../store/services/authServices";
import * as Yup from "yup";
import PageFooter from "./PageFooter";
const CreatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password must match"
  ),
});
const SuccessPage = () => {
  const history = useHistory();
  const goToLogin = () => {
    history.push("/login");
  };
  return (
    <Page>
      <div className="login-page">
        <Grid.Row>
          <div className="col-lg-6">
            <Grid.Row>
              <div
                className="col-md-6 col-lg-8 form-body"
                style={{ marginTop: "auto" }}
              >
                <label className="welcome-title">Success!</label>
                <label className="label-title">
                  {/* Your password has been reset successfully! */}
                  Please go back to the native app to login. 
                </label>
                {/* <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                  autoComplete="off"
                >
                  <button type="submit" className="btn btn-block btn-barlows">
                    Back to Log In
                  </button>
                </form> */}
              </div>
            </Grid.Row>
          </div>
          <div className='col-lg-6 form-body'>
            <div className="flex flex-column text-center">
              <img src={'/images/mm_logo.png'} alt='login' className='login-image' />
              <div>
                <div className="mm-logo-title">Mobility Measure</div>
                <div className="mm-logo-subtitle">measuring for the future</div>
              </div>
            </div>
          </div>
        </Grid.Row>
        <PageFooter />
      </div>
    </Page>
  );
};
function ResetPasswordPage(props) {
  const id = props.match.params.id;
  const params = new URLSearchParams(props.location.search);
  const token = params.get("token");
  // const email = params.get("email");
  const [isLoading, setSubmit] = useState(false);
  const [requested, setRequested] = useState(false);
  const [alert, setAlert] = useState({});
  const handleCreatePassword = (data) => {
    let createData = {
      ...{ token: token },
      ...data,
    };
    setSubmit(true);
    authService
      .resetPassword(id, createData)
      .then((res) => {
        setSubmit(false);
        setRequested(true);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: (err.response && err.response.data.message) || err.message,
        });
        setSubmit(false);
      });
  };
  return requested ? (
    <SuccessPage />
  ) : (
    <Formik
      initialValues={{
        password: "",
        password_confirmation: "",
      }}
      validationSchema={CreatePasswordSchema}
      onSubmit={(values) => {
        handleCreatePassword(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Page>
          <div className="login-page">
            <Grid.Row>
              <div className="col-lg-6">
                <Grid.Row>
                  <div className="col-md-6 col-lg-8 form-body">
                    <label className="welcome-title create-password">
                      Create New Password
                    </label>
                    <label className="label-title">
                      Create your password which will be used to log in to your
                      account.
                    </label>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="form-group">
                        <Field
                          name="password"
                          type="password"
                          className={
                            errors.password && touched.password
                              ? "form-control is-invalid state-invalid"
                              : "form-control"
                          }
                          placeholder="Password"
                        />
                        {errors.password && touched.password && (
                          <div className="invalid-feedback">
                            {errors.password}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Field
                          type="password"
                          name="password_confirmation"
                          className={
                            errors.password_confirmation &&
                            touched.password_confirmation
                              ? "form-control is-invalid state-invalid"
                              : "form-control"
                          }
                          placeholder="Confirm Password"
                        />
                        {errors.password_confirmation &&
                          touched.password_confirmation && (
                            <div className="invalid-feedback">
                              {errors.password_confirmation}
                            </div>
                          )}
                      </div>
                      {alert.message && (
                        <Alert type={alert.type}>{alert.message}</Alert>
                      )}
                      <button
                        type="submit"
                        className="btn btn-block btn-barlows"
                      >
                        Create Password
                      </button>
                    </form>
                  </div>
                </Grid.Row>
              </div>
              <div className="col-lg-6 ">
                <div className="ev-logo password-logo"></div>
              </div>
            </Grid.Row>
            <PageFooter />
          </div>
        </Page>
      )}
    />
  );
}

export default withRouter(ResetPasswordPage);

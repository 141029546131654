
import React, { useState, useEffect, useRef } from "react";
import { Page, Grid, Card, Alert, Form, Button } from "tabler-react";
import { Modal } from "react-bootstrap";
import SiteWrapper from "../../SiteWrapper";

import * as categoryServices from "../../store/services/categoryServices";

function EditCategory(props) {

    const [user, setUser] = useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});

    const [ loading, setLoading ] = useState(false);
    const [ id, setId ] = useState( Number( props.match.params.id ) );
    const [ errors, setErrors ] = useState({});
    const [ saveSuccess, setSaveSuccess ] = useState(false);
    const [ handleModalShow, setHandleModalShow] = useState(false);

    const [ category, setCategory] = useState("");

    useEffect(() => {
        if ( id > 0 ) {
            setLoading(true);

            categoryServices
                .getCategory(id)
                .then(( res ) => {
                    const data = JSON.parse(res.data);
                    
                    setCategory(data?.data?.category || "");

                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }, [  ])

    const saveUser = () => {
        setLoading(true);
        setErrors({});

        var formData = new FormData();

        formData.append("category", category);
        formData.append("id", id);


        categoryServices
            .saveCategory( formData )
            .then((res) => {
                setSaveSuccess(true);

                setLoading(false);
            })
            .catch((err) => {
                setErrors(err?.response?.data?.errors || {});
                setLoading(false);
            });
    }

    const handleClose = () => {
        setHandleModalShow(false);
    }

    const deleteCategory = () => {
        categoryServices
            .deleteCategory(id)
            .then(res => {
                const data = JSON.parse(res.data);
                if(data?.message === "Success") {
                    props.history.push("/categories");
                }
            })
            .catch(err => {

            })
    }


    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                <div className="page-header d-flex justify-content-between">
                    <h1 className="page-title">
                        Categories
                    </h1>

                </div>
                <Grid.Row>
                    <Grid.Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <Card.Title>
                                    Categories
                                </Card.Title>

                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        props.history.push("/categories");
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Card.Header>

                            <Card.Body >

                                <Grid.Row className="pb-5">
                                    <Grid.Col xl={ 2 } width={ 12 } ></Grid.Col>
                                    {
                                        !saveSuccess && 
                                        <Grid.Col xl={ 8 } width={ 12 }>
                                            <Grid.Row>
                                                <Grid.Col  width={ 12 } >
                                                    <Form.Group label="" >
                                                        <Form.Input
                                                            placeholder="Category Name"
                                                            onChange={(e) => setCategory(e.target.value)}
                                                            value={ category }
                                                            className="input-normal"
                                                            feedback={errors?.category && errors?.category[0]}
                                                            invalid={errors?.category}
                                                        />
                                                    </Form.Group>
                                                </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button className="btn-barlows d-flex justify-content-center align-items-center ml-2" onClick={() => saveUser()}>
                                                    {
                                                        id == 0 ?
                                                        "Add New" : "Save Changes"
                                                    }
                                                    {
                                                        id == 0 && 
                                                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.5 1.5V8.5" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                                            <path d="M8 5H1" stroke="white" strokeWidth="2" strokeLinecap="square"/>
                                                        </svg>
                                                    }

                                                </Button>
                                            </Grid.Row>
                                        </Grid.Col>
                                    }
                                    {
                                        saveSuccess && 
                                        <Grid.Col lg={8} width={12} >
                                            <Grid.Row>
                                                <h1 className="page-header-title">Save Success</h1>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <label className="text-secondary">
                                                    {
                                                        id != 0 ?
                                                        "Category successfully updated." : "New category successfully added."
                                                    }
                                                </label>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Button
                                                    color=" btn-barlows d-flex justify-content-center align-items-center"
                                                    onClick={() => {
                                                        props.history.push("/categories");
                                                    }}
                                                >
                                                    Back To Categories
                                                </Button>
                                            </Grid.Row>
                                        </Grid.Col>
                                    }

                                    
                                </Grid.Row>

                            </Card.Body>
                            
                            {
                                id > 0 && 
                                <Card.Header>
                                    <Grid.Col lg={2} md={ 0 }></Grid.Col>
                                    <div className='d-flex align-items-center'>
                                        <a href="#" className="btn btn-outline-danger d-flex align-items-center py-2" onClick={() => setHandleModalShow(true)}>Delete Category</a>
                                        <label className=' text-danger mb-0 ml-5'>Note: The category will no longer be able to use.</label>
                                    </div>
                                </Card.Header>
                            }
                        
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>


            <Modal
                show={handleModalShow}
                centered={true}
                onHide={handleClose}
                dialogClassName="delete-user cancel-subscription"
            >
                <Modal.Body>
                    <div
                        style={{ textAlign: "right", cursor: "pointer" }}
                        className='close-icon'
                    >
                        <svg
                        width="21px"
                        height="20px"
                        viewBox="0 0 32 32"
                        onClick={handleClose}
                        >
                        <g
                            stroke="#E5E9EF"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                            transform="translate(-419.000000, -240.000000)"
                            fill="#E5E9EF"
                            >
                            <g
                                id="icons"
                                transform="translate(56.000000, 160.000000)"
                            >
                                <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                            </g>
                            </g>
                        </g>
                        </svg>
                    </div>
                    <div className="d-flex justify-content-center align-items-center notification-content">
                        <svg width="50px" height="50px" viewBox="0 0 50 50">
                        <g
                            id="Desktop-App---BRANDED-V2"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                            id="Settings---Delete-User-Modal"
                            transform="translate(-695.000000, -376.000000)"
                            fill="#FF4747"
                            >
                            <g
                                id="Group-8"
                                transform="translate(530.000000, 336.000000)"
                            >
                                <g
                                id="Group-6"
                                transform="translate(165.000000, 40.000000)"
                                >
                                <rect
                                    id="Rectangle"
                                    fillOpacity="0.1"
                                    x="0"
                                    y="0"
                                    width="50"
                                    height="50"
                                    rx="5"
                                ></rect>
                                <g
                                    id="delete"
                                    transform="translate(15.000000, 15.000000)"
                                >
                                    <path
                                    d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z"
                                    id="Shape"
                                    ></path>
                                </g>
                                </g>
                            </g>
                            </g>
                        </g>
                        </svg>
                        <div className="content-title">Delete Category?</div>
                        <div className="content-note">
                            Are you sure? This cannot be undone.
                        </div>
                        <div
                        className="d-flex user-page"
                        style={{ marginTop: 20 }}
                        >
                        <Button
                            outline
                            color="danger"
                            onClick={ deleteCategory }
                        >
                            Yes, Delete Category
                        </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </SiteWrapper>
    )
}

export default EditCategory;
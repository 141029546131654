import * as React from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import { Alert } from "tabler-react";
import PageFooter from "./pages/PageFooter";
import { useDispatch } from "react-redux";
import * as authService from "./store/services/authServices";
import * as billService from "./store/services/billServices";
import { authLogout } from "./store/actions/authActions";
import Loading from "react-loading";
import { ReactComponent as PaymentSVG } from "./assets/images/Payments-nav.svg";
const SiteWrapper = (props, ...rest) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = React.useState((localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) || {});
  const [navExpanded, setNavExpanded] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(true);
  const containerRef = React.useRef(null);
  React.useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);
    window.addEventListener("storage", eventListenerFun);
    return () => window.removeEventListener("storage", eventListenerFun);
  }, []);
  const handleDocumentClick = (e) => {
    if (containerRef.current) {
      if (e.target !== containerRef && !containerRef.current.contains(e.target)) {
        setNavClose();
      }

    }
  };

  const eventListenerFun = (e) => {
    setUser(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")));
  };
  const handleSetNavExpanded = (expanded) => {
    setNavExpanded(expanded);
  };

  const setNavClose = () => {
    setNavExpanded(false);
  };
  const handleLogout = () => {
    authService
      .logout()
      .then(() => {
        dispatch(authLogout());
        history.push("/login");
        localStorage.clear();
      })
      .catch((error) => {
        dispatch(authLogout());
        history.push("/login");
        localStorage.clear();
      });
  };
  const firstName = () => {
    // if (user.name) {
    return user.name.split(" ")[0];
    // }
  };
  const lastName = () => {
    if (user.name) {
      return user.name.split(" ")[1];
    }
  };
  return (
    <div className='page'>
      {props.loading && (
        <div className='loading'>
          <Loading type='spinningBubbles' color='#171717' />
        </div>
      )}
      <div className='page-main'>
        <div className='header'>
          <div className='container'>
            <Navbar expand='md' onToggle={handleSetNavExpanded} expanded={navExpanded}>
              <Navbar.Toggle className='test' aria-controls='basic-navbar-nav' />
              <Navbar.Brand className='flex' href='/measure'>
                <img
                  src='/images/logo_icon.svg'
                  className='align-top app-logo1'
                  alt='EVAA'
                  style={{ width: 30, objectFit: "contain" }}
                />
                <div className='app-logo' />
              </Navbar.Brand>
              <Navbar.Collapse id='basic-navbar-nav' ref={containerRef}>
                <Nav className='mr-auto'>
                <NavLink className='nav-link' to='/measure'>
                    <svg width='17px' height='19px' viewBox='0 0 17 19' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-290.000000, -19.000000)' stroke='currentColor' strokeWidth='2'>
                          <g transform='translate(290.000000, 19.000000)'>
                            <rect id='Rectangle' x='6' y='10.5' width='5' height='7'></rect>
                            <path
                              d='M8.79180255,1.22422273 L16,6.50692595 L16,18 L1,18 L1,6.52894387 L8.79180255,1.22422273 Z'
                              id='Rectangle-Copy'
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Measure
                  </NavLink>
                  <NavLink className='nav-link payments' to='/products'>
                      <PaymentSVG />
                      Products
                  </NavLink>
                  <NavLink className='nav-link' to='/users'>
                    <svg width='19px' height='18px' viewBox='0 0 19 18' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-576.000000, -19.000000)' stroke='currentColor' strokeWidth='2'>
                          <g transform='translate(576.000000, 19.000000)'>
                            <circle id='Oval' cx='9.5' cy='5' r='4'></circle>
                            <path
                              d='M9.5,11 C11.7237444,11 13.7409431,11.7939736 15.1995972,13.0905551 C16.36307,14.1247531 17.1697713,15.4812292 17.4187489,17 L17.4187489,17 L1.58125109,17 C1.83022868,15.4812292 2.63692999,14.1247531 3.80040281,13.0905551 C5.25905693,11.7939736 7.27625556,11 9.5,11 Z'
                              id='Oval-Copy'
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Users
                  </NavLink>
                  <NavLink className='nav-link' exact to='/myaccount'>
                      <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1'>
                        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                          <g transform='translate(-821.000000, -17.000000)' stroke='currentColor' strokeWidth='2'>
                            <g transform='translate(822.000000, 18.000000)'>
                              <line x1='10' y1='20' x2='10' y2='0' id='Line'></line>
                              <line x1='20' y1='10' x2='0' y2='10' id='Line'></line>
                              <line x1='17.0710678' y1='2.92893219' x2='2.92893219' y2='17.0710678' id='Line'></line>
                              <line x1='2.92893219' y1='2.92893219' x2='17.0710678' y2='17.0710678' id='Line'></line>
                              <circle id='Oval' fill='#FFFFFF' cx='10' cy='10' r='6.5'></circle>
                            </g>
                          </g>
                        </g>
                      </svg>
                      My Account
                  </NavLink>
                    {
                        user?.type === "Admin" && 
                        <NavLink className='nav-link' exact to='/categories'>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.185 13.7813C19.309 13.9961 19.3428 14.2514 19.2789 14.4911C19.2151 14.7308 19.0588 14.9355 18.8444 15.0602L11.3444 19.4352C11.201 19.5189 11.0378 19.5631 10.8717 19.5631C10.7056 19.5631 10.5425 19.5189 10.3991 19.4352L2.89909 15.0602C2.68422 14.9349 2.52791 14.7295 2.46454 14.489C2.40118 14.2485 2.43594 13.9926 2.5612 13.7778C2.68645 13.5629 2.89193 13.4066 3.13243 13.3432C3.37293 13.2799 3.62875 13.3146 3.84362 13.4399L10.8749 17.5399L17.9069 13.4399C18.1218 13.3162 18.3769 13.2827 18.6165 13.3467C18.856 13.4107 19.0604 13.5669 19.185 13.7813ZM17.9069 9.68988L10.8757 13.7899L3.84831 9.68988C3.63405 9.57484 3.38347 9.54756 3.14948 9.6138C2.91549 9.68004 2.71639 9.83461 2.59422 10.0449C2.47205 10.2552 2.43637 10.5047 2.49471 10.7408C2.55305 10.9768 2.70085 11.181 2.9069 11.3102L10.4069 15.6852C10.5503 15.7689 10.7135 15.8131 10.8796 15.8131C11.0457 15.8131 11.2088 15.7689 11.3522 15.6852L18.8522 11.3102C19.0671 11.1848 19.2233 10.9793 19.2866 10.7387C19.3499 10.4981 19.3151 10.2422 19.1897 10.0274C19.0644 9.81251 18.8588 9.65624 18.6182 9.59295C18.3776 9.52965 18.1218 9.56452 17.9069 9.68988ZM2.43815 6.75003C2.43846 6.58576 2.48192 6.42446 2.56419 6.28227C2.64645 6.14009 2.76464 6.02201 2.9069 5.93988L10.4069 1.56488C10.5505 1.4816 10.7136 1.43774 10.8796 1.43774C11.0456 1.43774 11.2086 1.4816 11.3522 1.56488L18.8522 5.93988C18.9939 6.02239 19.1115 6.14063 19.1932 6.28279C19.2749 6.42495 19.318 6.58606 19.318 6.75003C19.318 6.91401 19.2749 7.07512 19.1932 7.21728C19.1115 7.35944 18.9939 7.47768 18.8522 7.56019L11.3522 11.9352C11.2088 12.0189 11.0457 12.0631 10.8796 12.0631C10.7135 12.0631 10.5503 12.0189 10.4069 11.9352L2.9069 7.56019C2.76464 7.47806 2.64645 7.35998 2.56419 7.2178C2.48192 7.07561 2.43846 6.9143 2.43815 6.75003ZM5.23659 6.75003L10.8757 10.0399L16.5147 6.75003L10.8757 3.46019L5.23659 6.75003Z" />
                            </svg>

                            Categories
                        </NavLink>
                    }
                    {
                        user?.type === "Admin" && 
                        <NavLink className='nav-link' exact to='/tags'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.0285 9.44516L10.4932 0.909851C10.3341 0.749665 10.1447 0.622667 9.93612 0.53622C9.72753 0.449773 9.50385 0.405598 9.27805 0.406257H1.31251C1.039 0.406257 0.7767 0.514907 0.583303 0.708303C0.389907 0.9017 0.281257 1.164 0.281257 1.43751V9.40305C0.280598 9.62885 0.324773 9.85253 0.41122 10.0611C0.497667 10.2697 0.624665 10.4591 0.784851 10.6182L9.32016 19.1535C9.64248 19.4757 10.0796 19.6567 10.5353 19.6567C10.9911 19.6567 11.4282 19.4757 11.7505 19.1535L19.0285 11.8755C19.3507 11.5532 19.5317 11.1161 19.5317 10.6603C19.5317 10.2046 19.3507 9.76748 19.0285 9.44516ZM10.5353 17.452L2.34376 9.25782V2.46876H9.13282L17.3244 10.6603L10.5353 17.452ZM6.81251 5.56251C6.81251 5.83446 6.73186 6.1003 6.58078 6.32642C6.42969 6.55253 6.21495 6.72877 5.9637 6.83284C5.71245 6.93691 5.43598 6.96414 5.16926 6.91109C4.90253 6.85803 4.65753 6.72708 4.46524 6.53478C4.27294 6.34248 4.14198 6.09748 4.08893 5.83076C4.03587 5.56403 4.0631 5.28757 4.16717 5.03632C4.27124 4.78507 4.44748 4.57032 4.6736 4.41924C4.89972 4.26815 5.16556 4.18751 5.43751 4.18751C5.80218 4.18751 6.15192 4.33237 6.40978 4.59024C6.66764 4.8481 6.81251 5.19783 6.81251 5.56251Z" />
                            </svg>
                            Tags
                        </NavLink>
                    }
                </Nav>
              </Navbar.Collapse>
              <Nav className='navbar-right'>
                <NavDropdown
                  className='user-info'
                  title={
                    user.name && (
                      <>
                        <span className="username">{user.name} </span>
                        {/* <span className='mobile-hide'>&nbsp;{lastName()}</span> */}
                      </>
                    )
                  }
                >
                  <NavLink exact className='dropdown-item' to='/myaccount'>
                    <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                        <g transform='translate(-821.000000, -17.000000)' stroke='currentColor' strokeWidth='2'>
                          <g transform='translate(822.000000, 18.000000)'>
                            <line x1='10' y1='20' x2='10' y2='0' id='Line'></line>
                            <line x1='20' y1='10' x2='0' y2='10' id='Line'></line>
                            <line x1='17.0710678' y1='2.92893219' x2='2.92893219' y2='17.0710678' id='Line'></line>
                            <line x1='2.92893219' y1='2.92893219' x2='17.0710678' y2='17.0710678' id='Line'></line>
                            <circle id='Oval' fill='#FFFFFF' cx='10' cy='10' r='6.5'></circle>
                          </g>
                        </g>
                      </g>
                    </svg>
                    My Account
                  </NavLink>
                  <NavDropdown.Divider />

                  <NavDropdown.Item onClick={handleLogout}>
                    <svg width='15px' height='12px' viewBox='0 0 15 12' version='1.1'>
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g transform='translate(-1103.000000, -119.000000)' fill='currentColor'>
                          <g transform='translate(1086.000000, 52.000000)'>
                            <g transform='translate(17.000000, 67.000000)'>
                              <path
                                d='M0,1.42 C0,0.6357553 0.6357553,0 1.42,0 L7.81,0 C8.594266,0 9.23,0.6357553 9.23,1.42 L9.23,2.84 C9.23,3.2321259 8.912133,3.55 8.52,3.55 C8.127867,3.55 7.81,3.2321188 7.81,2.84 L7.81,1.42 L1.42,1.42 L1.42,9.94 L7.81,9.94 L7.81,8.52 C7.81,8.127867 8.127867,7.81 8.52,7.81 C8.912133,7.81 9.23,8.127867 9.23,8.52 L9.23,9.94 C9.23,10.724266 8.594266,11.36 7.81,11.36 L1.42,11.36 C0.6357553,11.36 0,10.724266 0,9.94 L0,1.42 Z M10.857959,3.0479519 C11.135214,2.7706827 11.584786,2.7706827 11.862041,3.0479519 L13.992041,5.177959 C14.269296,5.455214 14.269296,5.904786 13.992041,6.182041 L11.862041,8.312041 C11.584786,8.589296 11.135214,8.589296 10.857959,8.312041 C10.580704,8.034786 10.580704,7.585214 10.857959,7.307959 L11.775918,6.39 L4.97,6.39 C4.5778812,6.39 4.26,6.072133 4.26,5.68 C4.26,5.287867 4.5778812,4.97 4.97,4.97 L11.775918,4.97 L10.857959,4.0520481 C10.580704,3.7747718 10.580704,3.3252282 10.857959,3.0479519 Z'
                                id='Shape'
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar>
          </div>
        </div>
        {props.children}
      </div>
      <div>
        <div className='container'>
          <PageFooter />
        </div>
      </div>
    </div>
  );
};

export default SiteWrapper;

// @flow

import * as React from "react";
import cn from "classnames";
import { useState, useEffect } from "react";
import "./GoogleMap.css";
import {
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  Marker,
  GoogleMap as ReactGoogleMap,
} from "react-google-maps";

const MapComponent = withGoogleMap((props) => {
  const getCenter = (map) => {
    let _bounds = new window.google.maps.LatLngBounds();
    props.stations.forEach((marker, index) => {
      const position = new window.google.maps.LatLng(marker.lat, marker.lon);
      _bounds.extend(position);
    });
    map.fitBounds(_bounds);
    return _bounds.getCenter();
  };
  return (
    <ReactGoogleMap
      ref={(map) => map && props.stations.length > 0 && getCenter(map)}
      defaultZoom={props.zoom}
      zoom={props.zoom}
      defaultCenter={{ lat: 53.47868, lng: -2.251672 }}
      // center={props.stations.length && { lat: props.stations[0].lat, lng: props.stations[0].lon }}
      // disableDefaultUI={true}
      options={{
        gestureHandling: "greedy",
        // zoomControl: false,
        zoomControlOptions: { position: 1 },
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        // mapTypeId: "333d7b4dda4f794f",
        styles: [
          {
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.country",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                weight: 3.5,
              },
            ],
          },
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                weight: 2,
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.locality",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.province",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                weight: 2,
              },
            ],
          },
          {
            featureType: "administrative.province",
            elementType: "geometry",
            stylers: [
              {
                weight: 2.5,
              },
            ],
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                weight: 2,
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f6f7f6",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "landscape.natural.landcover",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
        ],
      }}
    >
      {props.stations.map((item, key) => (
        <Marker
          key={key}
          position={{ lat: item.lat, lng: item.lon }}
          icon={{ url: "/images/marker.svg" }}
        />
      ))}
    </ReactGoogleMap>
  );
});

function GoogleMap({ blackAndWhite, stations }) {
  const containerClasses = cn("GoogleMapContainer", { blackAndWhite });
  const [zoom, setZoom] = React.useState(8);

  React.useEffect(() => {
    setTimeout(() => { setZoom(8) }, 1000)
  }, [stations]);
  return (
    <MapComponent
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div className={containerClasses} />}
      mapElement={<div style={{ height: `100%` }} />}
      stations={stations}
      zoom={zoom}
    />
  );
}

export default GoogleMap;
